<template>
  <div class="top-bar-container">
    <div class="brand-container">
      <a href="https:www.themenu.be">
        <div class="brand-logo">
          <BaseBrandIcon />
        </div>
        <div class="brand-name">The Menu</div>
      </a>
    </div>
    <!--
    <router-link to="/auth/register"> Registreer </router-link>
    <router-link to="/auth/login"> Login </router-link>
    -->
  </div>
</template>

<script>
import BaseBrandIcon from "@/components/BaseBrandIcon.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "TheAuthTopBar",
  components: {
    BaseBrandIcon,
  },
  data() {
    return {
      profileDropdownOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "user/user",
    }),
    currentPage() {
      return this.$route.path;
    },
  },
  methods: {
    ...mapActions({
      logout: "auth/logout",
    }),
    toggleProfileDropdown() {
      this.profileDropdownOpen = !this.profileDropdownOpen;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.top-bar-container {
  border-bottom: 1px solid $lm-divider-color;
  height: 100%;
  display: flex;
  align-items: center;

  .brand-container {
    height: 75px;
    padding-left: $standard-padding;
    display: flex;
    align-items: center;
    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: $lm-text-color;
      .brand-logo {
        background-color: $lm-brand-color;
        border-radius: $standard-radius;
        width: 55px;
        height: 55px;
        box-sizing: border-box;
        padding: 5px;
      }
      .brand-name {
        padding-left: 10px;
        font-family: $brand-font-family;
        font-size: 2rem;
        font-weight: 600;
        width: auto;
      }
    }
  }

  .top-bar-item {
    font-size: 1.2rem;
    margin-left: 35px;
    cursor: pointer;
    text-decoration: none;
    color: $lm-text-color;
    transition: 0.2s color;
  }
  .top-bar-item:hover {
    color: darken($lm-text-color, 30%);
  }
}
</style>
