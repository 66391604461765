<template>
  <div id="layout">
    <TheAuthTopBar id="the-auth-top-bar" />
    <router-view></router-view>
  </div>
  <div id="mobile-error">
    <a href="https://www.themenu.be"><BaseBrandIcon /></a>
    <p>
      Het The Menu portaal is ontworpen voor tablets en desktop apparaten. Voor
      een goede ervaring open je best deze pagina op je computer of tablet.
    </p>
  </div>
</template>

<script>
import TheAuthTopBar from "@/components/TheAuthTopBar.vue";
import BaseBrandIcon from "@/components/BaseBrandIcon.vue";
export default {
  name: "AuthLayout",
  components: {
    TheAuthTopBar,
    BaseBrandIcon,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

#layout {
  height: 100vh;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 75px auto 50px;

  #the-auth-top-bar {
    margin-left: calc(#{$standard-margin} * 3);
    margin-right: calc(#{$standard-margin} * 3);
  }
}

#mobile-error {
  display: none;
}

@media only screen and (max-width: 768px) {
  #layout {
    display: none;
  }

  #mobile-error {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-left: $standard-margin;
    margin-right: $standard-margin;

    a {
      width: 60px;
      height: 60px;
      background-color: $lm-brand-color;
      margin-bottom: $standard-margin;
      padding: 6px;
      box-sizing: border-box;
      border-radius: $standard-radius;
    }

    p {
      text-align: center;
    }
  }
}
</style>
